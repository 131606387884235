import { injectedConnector } from "./connectors";

export const connectWallet = async(activate,errorMessageCallback)=>{
    if(window.ethereum === undefined) {
        alert('Metamask not installed')
    }
    await activate(injectedConnector, async (error) => {
        console.log({ error });
        errorMessageCallback(error.message);
    });
}