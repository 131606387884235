    import {Container, Nav, Navbar,} from "react-bootstrap";
    import {useTranslation} from "react-i18next";
    import {Link, useLocation, useNavigate} from "react-router-dom";
    import logo from "../assets/images/download.png";
    import logoWithBackground from "../assets/images/jtc-ofwhite-logo.png";
    import whitepaper from "../assets/whitepaper.pdf";
    import {useWeb3React} from "@web3-react/core";
    import {connectWallet} from "../utils/connectWallet";
    import {useEagerConnect, useInactiveListener} from "../hooks/useEagerConnect";
    import {useRef, useState} from "react";
    import eventTrack from "../utils/eventTrack";

    function Header(props) {
        const languages = [
            {value: "en", text: "English"},
            {value: "es", text: "Spanish"},
            {value: "hi", text: "Hindi"},
            {value: "af", text: "Afrikaans"},
            {value: "ru", text: "Russian"},
            {value: "th", text: "Thai"},
            {value: "uk", text: "Ukrainian"},
            {value: "ur", text: "Urdu"},
        ];
        const [localLogo, setLocalLogo] = useState(
            localStorage.getItem("i18nextLng")
        );
        const {t} = useTranslation();
        const navigate = useNavigate();

        let langRef = useRef();
        const [lang, setLang] = useState("en");

        const [errorMessage, setErrorMessage] = useState();
        useEagerConnect(setErrorMessage);
        useInactiveListener();
        const {active, activate} = useWeb3React();
        const {hash} = useLocation();
        const languageHandler = (e) => {
            setLang(e);
            window.location.replace(`${window.location.origin}${hash}?lng=${e}`);
        };
        console.log(t("welcome_msg"));

        // const [navbar, setNavbar] = useState(false);

        // useEffect(() => {
        //     window.addEventListener("scroll", () => {
        //       if (window.pageYOffset > 80) {
        //         setNavbar(true);
        //       } else {
        //         setNavbar(false);
        //       }
        //     });
        //   }, []);

        function openNav() {
            document.getElementById("mySidenav").style.width = "90%";
        }

        function closeNav() {
            var x = window.matchMedia("(max-width: 800px)");
            if (x.matches) {
                // If media query matches
                document.getElementById("mySidenav").style.width = "0";
            }
        }

        function onClickNavMenu(action, label) {
            closeNav();
            eventTrack(action, label)
        }

        return (
            <>
                <Navbar
                    collapseOnSelect
                    expand="lg"
                    className={
                        props.type == "dark"
                            ? "custom-nav dark-header"
                            : "custom-nav light-header"
                    }
                    variant="dark"
                >
                    <Container fluid className="w-100">
              <span onClick={(e) => openNav()} className="opennav">
                <i className="fa fa-bars"></i>
              </span>
                        <div
                            id="mySidenav"
                            className="justify-content-md-around gap-2 sidenav"
                        >
                            <a href="#" className="closebtn" onClick={(e) => closeNav()}>
                                &times;
                            </a>
                            <Link to={"/"} className="logo" onClick={() => onClickNavMenu('Header Logo', '/')}>
                                {/* <h2>JTC</h2> */}
                                <img src={localLogo == "ur" ? logoWithBackground : logo}/>
                            </Link>
                            <Nav>
                                <Link to={"/"} onClick={() => onClickNavMenu('Header Menu', '/')}>
                                    {t("home")}
                                </Link>
                                <a href="#about"

                                   onClick={() => onClickNavMenu('About us', 'About us Information on Header')}
                                >
                                    {t("about_us")}
                                </a>
                                <a href="#tokenomics"
                                   onClick={() => onClickNavMenu('Tokenomics','Tokenomics Graph on Header')}
                                >
                                    {t("tokenomics")}
                                </a>
                                <div className="custom-dropdown-main">
                                    <Link to={"#"} className="buy-token-btn"
                                          onClick={() => eventTrack('Buy Token', 'Header Menu')}
                                    >
                                        <span>{t("buy_jtc")}</span>{" "}
                                        <i className="fa-solid fa-caret-down "></i>
                                    </Link>
                                    <div className="custom-drop-down">
                                        <Link to={"/public-sale"}
                                              onClick={() =>onClickNavMenu('Public Sale Page', '/public-sale')}
                                        >
                                            {t("public_sale")}
                                        </Link>
                                        <Link to={"/private-sale"} onClick={() => onClickNavMenu('Private Sale', '/private-sale')}>
                                            {t("private_sale")}
                                        </Link>
                                    </div>
                                </div>
                                <a href={whitepaper} target="_blank" download="whitepaper"
                                   onClick={()=>eventTrack('White Paper',whitepaper)}
                                >
                                    {t("white_paper")}
                                </a>
                                {/* <a href="#faq">FAQ</a> */}
                                <a href="#team" onClick={() => onClickNavMenu('Team', 'Header Menu')}>
                                    {t("team")}
                                </a>
                                <Link to={"/kyc"} onClick={() => onClickNavMenu('KYC', 'Header Menu')}>
                                    {t("KYC")}
                                </Link>
                                <div className="custom-dropdown-main">
                                    <Link to={"#"} className="buy-token-btn" onClick={()=>eventTrack('Language','Header Menu')}>
                                        <span>{t("Language")}</span>{" "}
                                        <i className="fa-solid fa-caret-down "></i>
                                    </Link>
                                    <div className="custom-drop-down">
                                        {/* <Link to={"/public-sale"} onClick={(e) => closeNav()}>
                        {t("public_sale")}
                      </Link>
                      <Link to={"/private-sale"} onClick={(e) => closeNav()}>
                        {t("private_sale")}
                      </Link> */}
                                        {languages.map((item, val) => {
                                            return (
                                                // <option
                                                //   className="custom-drop-down language-dropdown"
                                                //   to={"#"}
                                                //   key={val}
                                                //   value={item.value}
                                                // >
                                                //   {item.text}
                                                // </option>
                                                <Link
                                                    to="#"
                                                    key={val}
                                                    onClick={(e) => {
                                                        eventTrack('Language', item.text)
                                                        languageHandler(item.value)
                                                    }}
                                                >
                                                    {item.text}
                                                </Link>
                                            );
                                        })}
                                    </div>
                                </div>
                                {/* <select
                    // value={lang}
                    className="custom-dropdown-main language-drop-down-main"
                    onChange={languageHandler}
                  >
                    <Link to={"#"} className="buy-token-btn">
                      <span>Choose your language</span>{" "}
                      <i className="fa-solid fa-caret-down "></i>
                    </Link>

                    {languages.map((item, val) => {
                      return (
                        <option
                          className="custom-drop-down language-dropdown"
                          to={"#"}
                          key={val}
                          value={item.value}
                        >
                          {item.text}
                        </option>
                      );
                    })}
                  </select> */}
                            </Nav>

                            <div className="d-flex justify-content-center">
                                {active ? (
                                    <a href="#" className="custom-btn primary-btn buy-token-btn">
                                        Connected
                                    </a>
                                ) : (
                                    <a
                                        href="#"
                                        onClick={async() => {
                                            eventTrack('Wallet Connect', 'Header Menu')
                                            await connectWallet(activate, setErrorMessage);
                                        }}
                                        className="custom-btn primary-btn buy-token-btn"
                                    >
                                        {t("connect_wallet")}
                                    </a>
                                )}
                            </div>
                        </div>
                    </Container>
                </Navbar>
            </>
        );
    }

    export default Header;
