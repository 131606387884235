import {Col, Container, Row} from "react-bootstrap";
import {Header} from "../component";
import logo from "../assets/images/favicon.png";
import {useEffect, useState} from "react";
import {ethers} from "ethers";
import Crowdsale from "../contract/Crowdsale.json";
import {crowdsale_addr} from "../contract/addresses";
import Web3Modal from "web3modal";
import {useTranslation} from "react-i18next";
import ReactGA from "react-ga";
import eventTrack from "../utils/eventTrack";

function PublicSale() {
  /*TODO:: GOOGLE ANALYTICS CODE START*/
  ReactGA.pageview(window.location.pathname + window.location.search);
  /*GOOGLE ANALYTICS CODE END*/
  const {t} = useTranslation();
  // const {errorWeb3Modal} = useWeb3React();

  const [qty, setQty] = useState(1);
  const initialValue = 0.000001;
  const [price, setPrice] = useState(0.000001);

  const increase = () => {
    if (qty < 10000000) {
      setQty(qty + 1);
    }
  };

  const decrease = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };
  const loadProvider = async () => {
    try {
      if (window.ethereum === undefined)
        return alert('Metamask not installed')

      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      return provider.getSigner();
    } catch (e) {
      console.log('Error while connect web3modal', e)
    }
  };
  const buy = async (e) => {
    eventTrack('Buy JTC Token', 'Public Sale')
    try {
      let signer = await loadProvider();
      let _value = await ethers.utils.parseUnits(price.toString(), 8);
      let crowdsaleCon = new ethers.Contract(crowdsale_addr, Crowdsale, signer);
      let buy = await crowdsaleCon.buyTokens({value: _value});
      await buy.wait();
    } catch (e) {
    }
  };

  useEffect(() => {
    setPrice(initialValue * qty);
  }, [qty]);


  useEffect(() => {
    (async () => {
      await loadProvider();
    })()

  }, []);

  return (
    <>
      <Header type="dark"/>

      <section className="buy">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={6}>
              <div className="single-token">
                <img src={logo} alt='logo'/>
              </div>
            </Col>

            <Col lg={6}>
              <div className="single-token-des">
                <h5>{t("private_sale")}</h5>

                <h1>{t("jtc_token")}</h1>
                <p className="p-bold">{t("jtc_token_para")} </p>

                <h3>{parseFloat(price.toFixed(7))} BNB</h3>

                <div className="min-max">
                  <div className="increament">
                    <div
                      className="value-button decrease"
                      id="decrease"
                      value="Decrease Value"
                      onClick={(e) => decrease()}
                    >
                      -
                    </div>
                    <input
                      type="number"
                      id="room-number"
                      value={qty}
                      min="1"
                      max="100000"
                      className="number"
                      readOnly
                    />
                    <div
                      className="value-button increase"
                      id="increase"
                      value="Increase Value"
                      onClick={(e) => increase()}
                    >
                      +
                    </div>
                  </div>
                </div>

                <div className="token-place-order">
                  <button
                    className="custom-btn primary-btn"
                    onClick={(e) => buy()}
                  >
                    BUY JTC TOKEN
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default PublicSale;
