import React from "react";
import Slider from "react-slick";
import TestimonialCard from "material-testimonial-card";
import team from "../assets/images/team.png";

function Testimonial() {
  const settings = {
    dots: true,
    centerPadding: "60px",
    className: "center",
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerPadding: "20px",
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,

          centerPadding: "20px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          autoplaySpeed: 2000,
          centerPadding: "20px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          centerMode: false,
          autoplaySpeed: 2000,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <TestimonialCard
            name={"John Smith"}
            image={team}
            content={
              "Wow! This testimonial card is so amazing! I would like to use it in my project!"
            }
            project={"Testimonial card"}
            className="testimonial-class"
          />
        </div>
        <div>
          <TestimonialCard
            name={"John Smith"}
            image={team}
            content={
              "Wow! This testimonial card is so amazing! I would like to use it in my project!"
            }
            project={"Testimonial card"}
            className="testimonial-class"
          />
        </div>
        <div>
          <TestimonialCard
            name={"John Smith"}
            image={team}
            content={
              "Wow! This testimonial card is so amazing! I would like to use it in my project!"
            }
            project={"Testimonial card"}
            className="testimonial-class"
          />
        </div>
        <div>
          <TestimonialCard
            name={"John Smith"}
            image={team}
            content={
              "Wow! This testimonial card is so amazing! I would like to use it in my project!"
            }
            project={"Testimonial card"}
            className="testimonial-class"
          />
        </div>
      </Slider>
    </div>
  );
}

export default Testimonial;
