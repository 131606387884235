import chart from "../assets/images/chart.png"


function Tokenomics(){

    return(

        <>
        
            <img src={chart}/>

        </>

    )

}

export default Tokenomics