import {Accordion} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import eventTrack from "../utils/eventTrack";

function Faq() {
    const {t} = useTranslation();
    return (
        <>
            <Accordion
                defaultActiveKey={["0"]}
                alwaysOpen
                className="custom-accordian"
            >
                <Accordion.Item eventKey="0">
                    <Accordion.Header
                        onClick={() => eventTrack('Faq Question', 'Click on Faq Question')}
                    >
                      {t("faq_1")}</Accordion.Header>
                    <Accordion.Body>{t("faq_1_answer")}</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header
                        onClick={() => eventTrack('Faq Question', 'Click on Faq Question')}
                    >{t("faq_1")}</Accordion.Header>
                    <Accordion.Body>{t("faq_1_answer")}</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header
                        onClick={() => eventTrack('Faq Question', 'Click on Faq Question')}
                    >{t("faq_1")}</Accordion.Header>
                    <Accordion.Body>{t("faq_1_answer")}</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header
                        onClick={() => eventTrack('Faq Question', 'Click on Faq Question')}
                    >{t("faq_1")}</Accordion.Header>
                    <Accordion.Body>{t("faq_1_answer")}</Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}

export default Faq;
