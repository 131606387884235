import {useParams} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import {Container} from "react-bootstrap";
import {useEffect, useState} from "react";
import apis from "../services";
import Loader from "./Loader";

const BlogDetails = () => {
    const {blogId} = useParams();
    const [blog, setBlog] = useState({title: '', description: '', image: ''});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoader(true);
                const response = await apis.getBlog(blogId);
                const blog = response.data.blog;
                console.log(blog);
                setBlog(blog);
                setLoader(false);
            } catch (e) {
                console.error('Error occurred while fetching blog')
                setLoader(false)
            }
        })()
    }, []);

    if (loader) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Loader/>
        </div>
    }

    return (
        <>
            <Header type="dark"/>
            <section id="blog-details" className="blog-details-banner">
                <Container className="h-100">
                    <div className="h-100 py-5 d-flex align-items-center">
                        <div>
                            {" "}
                            {/*<h1 className="text-white">Blog Details</h1>{" "}*/}
                        </div>
                    </div>
                </Container>
            </section>

            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-box">
                                <div className="blog-meta">
                                    <div className="blog-img">
                                        <img
                                            src={blog.image}
                                            alt={blog.title}
                                        />
                                    </div>

                                    <h2>{blog.title}</h2>
                                    <p></p>
                                    <p>
                                        {blog.description}
                                    </p>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default BlogDetails;
