import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';


function Newsletter() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  // const [loader, setLoader]

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    emailjs.init('UyWlw7gHtIOd_cFx7');
    emailjs.send('service_dmxy6ev','template_3uk2zx7', {
        from_name: "Java Times Caffé",
        from_email: email,
    }, 'UyWlw7gHtIOd_cFx7')
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert(t('Thank_you_for_subscribe'))
            setEmail('');
        }, (err) => {
          console.log('FAILED...', err);
        });
  }

  return (
    <div className="news ">
      <div className="container">
        <h1 className="news-heading">
          {t("news_letter_heading")} <br /> {t("about_us")}
        </h1>
        <p className="des how-de">{t("news_letter_para")}</p>

        <form onSubmit={onSubmitHandler} dir="ltr">
          <input
            type="email"
            maxLength="50"
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Enter your email address"
          />
          <button className="bt" type='submit'>{t("subscribe_btn")}</button>
        </form>
      </div>
    </div>
  );
}

export default Newsletter;
