import axios from "axios";

const createBackendServer = baseURL => {
    const api = axios.create({
        baseURL,
        headers: {'Accept': 'application/json'},
        timeout: 60 * 1000
    });

    const headers = {
        'Content-Type': 'multipart/form-data'
    }

    /*==========    GET REQUESTS    ==========*/
    const getBlogs = async () => api.get('/blogs');
    const getBlog = async (id) => api.get(`/blogs/${id}`);
  const submitKycData = async (body) => api.post('/users/user_kyc', body, {headers})
    /*==========    POST REQUESTS    ==========*/
    /*==========    DELETE REQUESTS    ==========*/
    /*==========    PUT REQUESTS    ==========*/


    return {getBlogs,getBlog,submitKycData}
};

const SERVER_URL = process.env.REACT_APP_API_BASE_URL;

const apis = createBackendServer(SERVER_URL);


export default apis;
